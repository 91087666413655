import React, { useEffect, useState } from "react";
import { sendIcon, fileIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { addPost, updatePost } from "../../features/postSlice";
import useFetch from "../../hooks/useFetch";
import Compress from "compress.js";
import { closeIcon } from "../../assets/index";
import "./createpost.css";
import Accordion from "react-bootstrap/Accordion";
import { MdFoodBank } from "react-icons/md";
import { getAccountDetails } from "../../features/transactionSlice";
import { FaLocationDot } from "react-icons/fa6";

const initialForm = {
  image: null,
  preview: null,
  caption: "",
  location: "",
  lat: "",
  lng: "",
  ingredients: "",
  isDonate: true,
  amount: 3.0,
  availableDateTime: new Date().toISOString().split(".")[0],
  expireDateTime: new Date().toISOString().split(".")[0],
  diatryPreferences: [],
  categories: [],
};

const CreatePost = ({ post, id, close, userId }) => {
  const {
    users: { users },
    transaction: { accountDetails, accountLinks },
  } = useSelector((state) => state);

  useEffect(() => {
    let formData = new FormData();
    const user = users.find((user) => user._id === userId) || {};
    if (userId && user != {}) {
      formData.append("accountID", user.account_id);
      dispatch(
        getAccountDetails({
          customFetch,
          formData,
        })
      );
    }
  }, [users]);

  // local states
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (
      accountDetails &&
      accountDetails.data &&
      !accountDetails.data.payouts_enabled
    ) {
      setForm({
        ...form,
        isDonate: true,
      });
    }
  }, [accountDetails, accountLinks]);

  useEffect(() => {
    if (post && post._id) {
      setForm({
        ...post,
        diatryPreferences: JSON.parse(post.diatryPreferences[0]),
        categories: JSON.parse(post.categories[0]),
        image: null,
        preview: post.image?.src,
        caption: post.caption,
      });
    }
  }, [post]);

  const dispatch = useDispatch();
  const customFetch = useFetch();
  const compress = new Compress();

  const compressImage = async (files) => {
    const options = {
      size: 1,
      quality: 0.75,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: true,
      rotate: false,
    };
    const data = await compress.compress(files, options);
    return data;
  };

  const loadImage = async (e) => {
    const input = e.target;
    if (!input) return;
    var reader = new FileReader();
    reader.onload = function (e) {
      setForm((form) => ({ ...form, preview: e.target.result }));
    };
    input.files[0] && reader.readAsDataURL(input.files[0]);
    const files = [...input.files];
    const data = await compressImage(files);
    const image = Compress.convertBase64ToFile(data[0]?.data, data[0]?.ext);
    setForm((form) => ({ ...form, image }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", form.image);

    formData.append("caption", form.caption.trim());
    formData.append("location", form.location.trim());
    formData.append("ingredients", form.ingredients);
    formData.append("isDonate", form.isDonate);
    formData.append("amount", form.amount);
    formData.append("availableDateTime", form.availableDateTime);
    formData.append("expireDateTime", form.expireDateTime);
    formData.append(
      "diatryPreferences",
      JSON.stringify(form.diatryPreferences)
    );
    formData.append("categories", JSON.stringify(form.categories));
    formData.append("lat", form.lat);
    formData.append("lng", form.lng);
    //end to do : milan add all other fields

    if (post?._id) {
      dispatch(updatePost({ customFetch, id: post._id, formData }));
      close();
    } else {
      dispatch(addPost({ customFetch, formData }));
    }
    setForm(initialForm);
  };

  const onDiatryPreferencesChange = (value) => {
    let index = -1;
    if (form.diatryPreferences.length !== 0) {
      index = form.diatryPreferences.indexOf(value);
    }

    if (index > -1) {
      setForm((form) => ({
        ...form,
        diatryPreferences: form.diatryPreferences.filter(
          (item) => item !== value
        ),
      }));
    } else {
      setForm((form) => ({
        ...form,
        diatryPreferences: [...form.diatryPreferences, value],
      }));
    }
  };

  const onCategoriesChange = (value) => {
    let index = -1;
    if (form.categories.length !== 0) {
      index = form.categories.indexOf(value);
    }
    if (index > -1) {
      setForm((form) => ({
        ...form,
        categories: form.categories.filter((item) => item !== value),
      }));
    } else {
      setForm((form) => ({
        ...form,
        categories: [...form.categories, value],
      }));
    }
  };

  const fetchCurrentLocation = () => {
    console.log("current location");
    navigator.geolocation.getCurrentPosition((position) => {
      //get Reverse location from lat and lng
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      )
        .then((res) => res.json())
        .then((data) => {
          setForm({
            ...form,
            location: data.display_name,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
    });
  };

  return (
    <>
      {/* ----------Original---------- */}
      <>
        <Accordion>
          <Accordion.Item eventKey="0" className="border-none">
            {/* make accordian header dark and add logo and text */}

            <Accordion.Header className="">
              <MdFoodBank size={30} color="#dc3545" />
              <h4
                className="post-header mt-3 m-2"
                style={{
                  color: "#dc3545",
                  textShadow: "2px 2px 5px red;",
                }}
              >
                Post your food here
              </h4>
            </Accordion.Header>

            <Accordion.Body className="bg-dark">
              <form onSubmit={submitHandler}>
                <textarea
                  required
                  value={form.caption}
                  placeholder="Post your food item, What you want to share with others?"
                  onChange={(e) => {
                    setForm({ ...form, caption: e.target.value });
                  }}
                  className="w-100 caption"
                />
                <div className="row">
                  <div className="col-5">
                    <input
                      type="text"
                      className="location"
                      required
                      value={form.location}
                      placeholder="Where is it?"
                      onChange={(e) =>
                        setForm({ ...form, location: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <a
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        fetchCurrentLocation();
                      }}
                      title="Fetch current location"
                    >
                      <FaLocationDot />
                    </a>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="courses"
                      required
                      value={form.ingredients}
                      placeholder="Ingredients..."
                      onChange={(e) =>
                        setForm({ ...form, ingredients: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="left-text">What do you want?</p>

                    {accountDetails &&
                    accountDetails.data &&
                    !accountDetails.data.payouts_enabled ? (
                      <>
                        <p className="donation-text">
                          Please verify your bank details to sell
                        </p>
                      </>
                    ) : (
                      <>
                        <div class="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            class="onoffswitch-checkbox"
                            id="myonoffswitch"
                            checked={form.isDonate}
                            onChange={(e) =>
                              setForm({ ...form, isDonate: e.target.checked })
                            }
                          />
                          <label class="onoffswitch-label" for="myonoffswitch">
                            <span class="onoffswitch-inner"></span>
                            <span class="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-6 pt-2 donation-option">
                    {form.isDonate ? (
                      <div>
                        <p className="donation-text">
                          Thank you for donating, your food is now available for
                          free.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <input
                          type="number"
                          className="amount"
                          placeholder="Enter Amount"
                          required
                          value={form.amount}
                          onChange={(e) =>
                            setForm({ ...form, amount: e.target.value })
                          }
                          // max={9.0}
                          min={3.0}
                        />

                        <p className="donation-text">
                          Service fee: 1.99 euro. You will receive{" "}
                          {form.amount - 1.99} euro.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="left-text">When it will expire?</p>
                    <input
                      type="datetime-local"
                      name="expireDateTime"
                      className="date"
                      required
                      value={form.expireDateTime}
                      onChange={(e) =>
                        setForm({ ...form, expireDateTime: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="d-block tag-div-border">
                  <p className="left-text tag-text">Dietary Preferences</p>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Vegetarian")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Vegetarian")}
                  >
                    <i className="fa fa-carrot" style={{ color: "orange" }}></i>
                    <i>Vegetarian</i>
                  </span>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Non-Vegetarian")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Non-Vegetarian")}
                  >
                    <i className="fa fa-fish" style={{ color: "blue" }}></i>
                    <i>Non-Vegetarian</i>
                  </span>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Vegan")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Vegan")}
                  >
                    <i className="fa fa-leaf" style={{ color: "green" }}></i>
                    <i>Vegan</i>
                  </span>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Dairy Free")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Dairy Free")}
                  >
                    <i
                      className="fa fa-glass-whiskey"
                      style={{ color: "white" }}
                    ></i>
                    <i>Dairy Free</i>
                  </span>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Spicy")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Spicy")}
                  >
                    <i
                      className="fa fa-pepper-hot"
                      style={{ color: "red" }}
                    ></i>
                    <i>Spicy</i>
                  </span>
                  <span
                    className={
                      form.diatryPreferences &&
                      form.diatryPreferences.includes("Organic")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onDiatryPreferencesChange("Organic")}
                  >
                    <i
                      className="fa fa-seedling"
                      style={{ color: "green" }}
                    ></i>
                    <i>Organic</i>
                  </span>
                </div>
                <div className="d-block tag-div-border">
                  <p className="left-text tag-text">Category</p>
                  {/* home made food */}
                  <span
                    className={
                      form.categories &&
                      form.categories.includes("Home Made food")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Home Made food")}
                  >
                    <i className="fa fa-home" style={{ color: "blue" }}></i>
                    <i>Home Made food</i>
                  </span>
                  {/* restaurant food */}
                  <span
                    className={
                      form.categories &&
                      form.categories.includes("Restaurant food")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Restaurant food")}
                  >
                    <i className="fa fa-utensils" style={{ color: "red" }}></i>
                    <i>fast food</i>
                  </span>

                  {/* fruits */}
                  <span
                    className={
                      form.categories && form.categories.includes("Fruits")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Fruits")}
                  >
                    <i
                      className="fa fa-apple-alt"
                      style={{ color: "green" }}
                    ></i>
                    <i>Fruits</i>
                  </span>
                  {/* bakery */}
                  <span
                    className={
                      form.categories && form.categories.includes("Bakery")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Bakery")}
                  >
                    <i
                      className="fa fa-birthday-cake"
                      style={{ color: "brown" }}
                    ></i>
                    <i>Bakery</i>
                  </span>
                  {/* vegetables */}
                  <span
                    className={
                      form.categories && form.categories.includes("Vegetables")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Vegetables")}
                  >
                    <i className="fa fa-carrot" style={{ color: "orange" }}></i>
                    <i>Vegetables</i>
                  </span>
                  {/* drinks */}
                  <span
                    className={
                      form.categories && form.categories.includes("Drinks")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Drinks")}
                  >
                    <i
                      className="fa fa-glass-whiskey"
                      style={{ color: "white" }}
                    ></i>
                    <i>Drinks</i>
                  </span>
                  {/* snacks */}
                  <span
                    className={
                      form.categories && form.categories.includes("Snacks")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Snacks")}
                  >
                    <i
                      className="fa fa-cookie-bite"
                      style={{ color: "brown" }}
                    ></i>
                    <i>Snacks</i>
                  </span>
                  {/* sweets */}
                  <span
                    className={
                      form.categories && form.categories.includes("Sweets")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Sweets")}
                  >
                    <i
                      className="fa fa-candy-cane"
                      style={{ color: "red" }}
                    ></i>
                    <i>Sweets</i>
                  </span>
                  {/* grocery */}
                  <span
                    className={
                      form.categories && form.categories.includes("Grocery")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Grocery")}
                  >
                    <i
                      className="fa fa-shopping-basket"
                      style={{ color: "orange" }}
                    ></i>
                    <i>Grocery</i>
                  </span>

                  {/* other */}
                  <span
                    className={
                      form.categories && form.categories.includes("Other")
                        ? "tag bg-danger"
                        : "tag"
                    }
                    onClick={() => onCategoriesChange("Other")}
                  >
                    <i
                      className="fa fa-ellipsis-h"
                      style={{ color: "black" }}
                    ></i>
                    <i>Other</i>
                  </span>
                </div>

                {form.preview && (
                  <div className="uploaded-image">
                    <img src={form.preview} alt="uploaded file" />
                    <div
                      className="close-icon"
                      onClick={() =>
                        setForm({ ...form, image: null, preview: null })
                      }
                    >
                      <img src={closeIcon} alt="remove" />
                    </div>
                  </div>
                )}
                <div className="btns">
                  <label htmlFor={id || "image"} aria-label="select file">
                    <div>
                      <img src={fileIcon} alt="select file" />
                    </div>
                  </label>
                  <input
                    type="file"
                    id={id || "image"}
                    accept="image/png, image/jpeg"
                    onChange={loadImage}
                  />
                  <button type="submit" aria-label="submit">
                    <img src={sendIcon} alt="send" />
                  </button>
                </div>
              </form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
      {/* -------------End Original-------- */}
    </>
  );
};

export default CreatePost;
