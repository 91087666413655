import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { setIsLoading } from "../../features/modalSlice";
import { changePasswordService } from "../../services/authServices";
import { logout } from "../../features/userSlice";
import { showModal } from "../../features/modalSlice.js";
import { fullLogo } from "../../assets";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [OTP, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conformPaswword, setConfirmPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();
  const customFetch = useFetch();

  const ChangePasswordHandler = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    const data = await customFetch(changePasswordService, {
      otp: OTP,
      userId: id,
      password: newPassword,
    });
    data && dispatch(logout());
    dispatch(setIsLoading(false));
    dispatch(showModal({ msg: "Password change successfully!" }));
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    if (
      conformPaswword === newPassword &&
      newPassword !== "" &&
      conformPaswword !== ""
    ) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
  }, [newPassword, conformPaswword]);

  return (
    <>
      <section className="auth signup">
        <form onSubmit={ChangePasswordHandler} className="ChangePassword">
          <div className="login-title">
            <img src={fullLogo} alt="logo" />
          </div>
          <div className="email">
            <label htmlFor="OTP">OTP</label>
            <input
              type="number"
              id="OTP"
              placeholder="Enter OTP"
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
            />
          </div>

          <div className="email">
            <label htmlFor="new-password">New Password</label>
            <input
              type="password"
              id="new-password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="email">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Enter Confirm New Password"
              value={conformPaswword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <button type="submit" disabled={!isSubmit}>
            Change Password
          </button>

          <p>
            Remember the password? <br />
            <Link to="/">Login</Link>
          </p>
        </form>
      </section>
    </>
  );
};

export default ChangePassword;
