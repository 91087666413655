import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createTransactionService,
  getTransactionsService,
  createPaymentIntentService,
  getAccountDetailsService,
  getAccountLinkService,
} from "../services/transactionServices";
import { showModal } from "./modalSlice";

const initialState = {
  allTransactions: [],
  paymentIntent: null,
  accountDetails: null,
  accountLinks: null,
};

const handleGuest = (isGuest, dispatch) => {
  if (isGuest) {
    dispatch(showModal({ msg: "You must be logged in to do this action!!" }));
    return true;
  }
  return false;
};

export const createTransaction = createAsyncThunk(
  "transaction/createTransaction",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(createTransactionService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "Transaction Submitted successfully!" }));
    window.location.reload();
    return fulfillWithValue(data.transaction);
  }
);

export const createPaymentIntent = createAsyncThunk(
  "transaction/create-payment-intent",
  async (props, thunkAPI) => {
    debugger;
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(createPaymentIntentService, formData);
    if (!data) return rejectWithValue();
    return fulfillWithValue(data);
  }
);

export const getTransactions = createAsyncThunk(
  "transaction/getTransactions",
  async (props, thunkAPI) => {
    const { customFetch } = props;
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const data = await customFetch(getTransactionsService);
    if (!data) return rejectWithValue();
    return fulfillWithValue(data.transaction);
  }
);

export const getAccountDetails = createAsyncThunk(
  "transaction/retriveAccountDetails",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(getAccountDetailsService, formData);
    if (!data) return rejectWithValue();
    return fulfillWithValue(data);
  }
);

export const getAccountLink = createAsyncThunk(
  "transaction/generateAccountLinks",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(getAccountLinkService, formData);
    if (!data) return rejectWithValue();
    return fulfillWithValue(data);
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      state.allTransactions = action.payload;
    },
  },
  extraReducers: {
    [createTransaction.fulfilled]: (state, action) => {
      state.allTransactions.push(action.payload);
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.allTransactions = action.payload;
    },
    [createPaymentIntent.fulfilled]: (state, action) => {
      state.paymentIntent = action.payload;
    },
    [getAccountDetails.fulfilled]: (state, action) => {
      state.accountDetails = action.payload;
    },
    [getAccountLink.fulfilled]: (state, action) => {
      state.accountLinks = action.payload;
    },
  },
});

export const { setTransactions } = transactionSlice.actions;
export default transactionSlice.reducer;
