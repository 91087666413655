import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFoodRequestService,
  getFoodRequestDoneByUserService,
  getFoodRequestToUserService,
  updateFoodRequestService,
  approveFoodRequestService,
  rejectFoodRequestService,
} from "../services/requestfoodServices";
import { showModal } from "./modalSlice";

const initialState = {
  allFoodRequestDoneByUser: [],
  allFoodRequestToUser: [],
};

const handleGuest = (isGuest, dispatch) => {
  if (isGuest) {
    dispatch(showModal({ msg: "You must be logged in to do this action!!" }));
    return true;
  }
  return false;
};

export const createFoodRequest = createAsyncThunk(
  "foodRequest/createFoodRequest",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(createFoodRequestService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "Request Submitted successfully!" }));
    return fulfillWithValue(data.requestFood);
  }
);

export const getFoodRequestDoneByUser = createAsyncThunk(
  "foodRequest/getFoodRequestDoneByUser",
  async (props, thunkAPI) => {
    const { customFetch } = props;
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const data = await customFetch(getFoodRequestDoneByUserService);
    if (!data) return rejectWithValue();
    return fulfillWithValue(data.requestFood);
  }
);

export const getFoodRequestToUser = createAsyncThunk(
  "foodRequest/getFoodRequestToUser",
  async (props, thunkAPI) => {
    const { customFetch } = props;
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const data = await customFetch(getFoodRequestToUserService);

    if (!data) return rejectWithValue();
    return fulfillWithValue(data.requestFood);
  }
);

export const updateFoodRequest = createAsyncThunk(
  "foodRequest/updateFoodRequest",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(updateFoodRequestService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "Request Updated successfully!" }));
    return fulfillWithValue(data.requestFood);
  }
);

export const approveFoodRequest = createAsyncThunk(
  "foodRequest/approveFoodRequest",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(approveFoodRequestService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "Request Approved successfully!" }));
    return fulfillWithValue(data.requestFood);
  }
);

export const rejectFoodRequest = createAsyncThunk(
  "foodRequest/rejectFoodRequest",
  async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
      user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(rejectFoodRequestService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "Request Rejected successfully!" }));
    return fulfillWithValue(data.requestFood);
  }
);

const foodRequestSlice = createSlice({
  name: "foodRequest",
  initialState,
  extraReducers: {
    [createFoodRequest.fulfilled]: (state, action) => {
      state.allFoodRequestDoneByUser.unshift(action.payload);
    },
    [getFoodRequestDoneByUser.fulfilled]: (state, action) => {
      state.allFoodRequestDoneByUser = [];
      state.allFoodRequestDoneByUser.unshift(...action.payload);
    },
    [getFoodRequestToUser.fulfilled]: (state, action) => {
      state.allFoodRequestToUser = [];
      state.allFoodRequestToUser.unshift(...action.payload);
    },
    [updateFoodRequest.fulfilled]: (state, action) => {
      const { _id } = action.payload;
      const index = state.allFoodRequestDoneByUser.findIndex(
        (ele) => ele._id === _id
      );
      state.allFoodRequestDoneByUser[index] = action.payload;
    },
    [approveFoodRequest.fulfilled]: (state, action) => {
      const { _id } = action.payload;
      const index = state.allFoodRequestToUser.findIndex(
        (ele) => ele._id === _id
      );
      state.allFoodRequestToUser[index] = action.payload;
    },
    [rejectFoodRequest.fulfilled]: (state, action) => {
      const { _id } = action.payload;
      const index = state.allFoodRequestToUser.findIndex(
        (ele) => ele._id === _id
      );
      state.allFoodRequestToUser[index] = action.payload;
    },
  },
});

export const { getAllFoodRequestDoneByUser } = foodRequestSlice.actions;
export default foodRequestSlice.reducer;
