import extractParams from "../utils/extractParams";
import axiosConfig from "./axiosConfig";

const loginService = async (formData = {}) => {
  const params = extractParams(formData, "email", "password");
  const { data } = await axiosConfig.post("/auth/login", params);
  return data;
};

const registerService = async (formData = {}) => {
  const params = extractParams(
    formData,
    "name",
    "email",
    "password",
    "dob",
    "location",
    "lat",
    "lng"
  );
  const { data } = await axiosConfig.post("/auth/register", params);
  return data;
};

const sendForgotOTPService = async (formData = {}) => {
  const params = extractParams(formData, "email");
  let data = await axiosConfig.post("/auth/forgot-password", params);

  return data;
};

const changePasswordService = async (formData = {}) => {
  const params = extractParams(formData, "userId", "password", "otp");
  let data = await axiosConfig.post("/auth/change-password", params);
  return data;
};

export {
  loginService,
  registerService,
  sendForgotOTPService,
  changePasswordService,
};
