import axiosConfig from "./axiosConfig";

const createFoodRequestService = async (formData = {}) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const { data } = await axiosConfig.post("/foodRequest/create", formData, {
    headers,
  });
  return data;
};

const getFoodRequestDoneByUserService = async () => {
  const { data } = await axiosConfig.get("/foodRequest/byuser");
  return data;
};

const getFoodRequestToUserService = async () => {
  const { data } = await axiosConfig.get("/foodRequest/touser");
  return data;
};

const updateFoodRequestService = async (formData = {}) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const { _id } = formData;
  const { data } = await axiosConfig.put(
    `/foodRequest/update/${_id}`,
    formData,
    {
      headers,
    }
  );
  return data;
};

const approveFoodRequestService = async (formData = {}) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const { data } = await axiosConfig.post(`/foodRequest/approve`, formData, {
    headers,
  });
  return data;
};

const rejectFoodRequestService = async (formData = {}) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const { _id } = formData;
  const { data } = await axiosConfig.post(`/foodRequest/reject`, formData, {
    headers,
  });
  return data;
};

export {
  createFoodRequestService,
  getFoodRequestDoneByUserService,
  getFoodRequestToUserService,
  updateFoodRequestService,
  approveFoodRequestService,
  rejectFoodRequestService,
};
