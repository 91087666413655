import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../features/modalSlice";
import { login } from "../../features/userSlice";
import useFetch from "../../hooks/useFetch";
import { registerService } from "../../services/authServices";
import DataList from "../DataList/DataList";
import { fullLogo } from "../../assets";

const initialForm = { name: "", password: "", email: "", dob: "" };

const Register = ({ setIsRegistering }) => {
  const [form, setForm] = useState(initialForm);
  const dispatch = useDispatch();
  const customFetch = useFetch();

  useEffect(() => {
    //using react leaflet to get user location and set it to the form
    navigator.geolocation.getCurrentPosition((position) => {
      //get Reverse location from lat and lng
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      )
        .then((res) => res.json())
        .then((data) => {
          setForm((form) => ({
            ...form,
            location: data.display_name,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
        });
      setForm((form) => ({
        ...form,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }));
    });
    //if user denies location access then hide the form
    // navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //   if (result.state === "denied") setIsRegistering(false);
    // });
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    const data = await customFetch(registerService, form);
    if (data) dispatch(login(data));
    dispatch(setIsLoading(false));
  };

  const updateForm = (key, e) => {
    setForm((form) => ({ ...form, [key]: e.target.value }));
  };

  return (
    <>
      <form onSubmit={registerHandler} className="register">
        <div className="login-title">
          <img src={fullLogo} alt="logo" />
        </div>
        <div className="email">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="johndoe@example.com"
            value={form.email}
            required
            onChange={(e) => updateForm("email", e)}
          />
          <DataList
            email={form.email}
            setEmail={(value) => setForm((form) => ({ ...form, email: value }))}
          />
        </div>
        <label htmlFor="name">Username</label>
        <input
          type="text"
          id="name"
          placeholder="john doe"
          value={form.name}
          required
          onChange={(e) => updateForm("name", e)}
        />
        <label htmlFor="dob">Date of birth</label>
        <input
          type="date"
          id="dob"
          required
          value={form.dob}
          onChange={(e) => updateForm("dob", e)}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Top secret"
          required
          value={form.password}
          onChange={(e) => updateForm("password", e)}
        />
        {/* embeded leaflet map into div */}
        <label htmlFor="location">Location</label>
        <input
          type="text"
          id="location"
          placeholder="Current location"
          required
          value={form.location}
          onChange={(e) => updateForm("location", e)}
        />
        <button type="submit">Register</button>
        <p>
          Already have an account?{" "}
          <span onClick={() => setIsRegistering(false)}>Login</span>
        </p>
      </form>
    </>
  );
};

export default Register;
