import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { fetchPostService } from "../../services/postServices";
import { dp } from "../../assets";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import Backdrop from "../Backdrop/Backdrop";
import Payment from "../Post/payment";

const FoodRequest = () => {
  const dispatch = useDispatch();
  const customFetch = useFetch();

  const [postDetails, setPostDetails] = React.useState();
  const [foodRequestDetails, setFoodRequestDetails] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  let foodRequests = useSelector(
    (state) => state.foodRequest.allFoodRequestDoneByUser
  );

  let foodRequestsToUser = useSelector(
    (state) => state.foodRequest.allFoodRequestToUser
  );

  const getPostFullDetails = async (id, foodRequest) => {
    const data = await customFetch(fetchPostService, id);
    setPostDetails(data.post);
    setIsModalOpen(true);
    setFoodRequestDetails(foodRequest);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getPostDetails = (id, foodRequest) => {
    return (
      <>
        <button
          className="btn btn-success btn-sm btn-block"
          onClick={() => getPostFullDetails(id, foodRequest)}
        >
          {" "}
          Process
        </button>
      </>
    );
  };

  return (
    <section className="online request-approval">
      {foodRequestsToUser.lenght < 0 && (
        <>
          <h2>Food Request from you</h2>

          <div class="list-group">
            {foodRequestsToUser.map((foodRequest) => {
              return (
                <Link
                  to={`/post/${
                    foodRequest && foodRequest.postID && foodRequest.postID._id
                      ? foodRequest.postID._id
                      : foodRequest.postID
                  }`}
                  class="list-group-item list-group-item-action d-flex gap-3 py-3"
                  aria-current="true"
                >
                  <img
                    src={
                      foodRequest.createdByImage
                        ? foodRequest.createdByImage
                        : dp
                    }
                    alt="twbs"
                    width="32"
                    height="32"
                    class="rounded-circle flex-shrink-0"
                  />

                  <div class="d-flex gap-2 w-100 justify-content-between">
                    <div>
                      <h6 class="mb-0">{foodRequest.createdByUserName}</h6>
                      <p class="mb-0 opacity-75">
                        {foodRequest.requestMessage}
                      </p>
                    </div>
                    <small class="opacity-50 text-nowrap">
                      <ReactTimeAgo
                        date={foodRequest.createdAt}
                        locale="en-US"
                      />
                    </small>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}

      <h3>Your Request</h3>
      {/* for each and every food request create a block which display you have requested this food from this user */}

      <div class="list-group">
        {foodRequests
          .filter(
            (foodRequest) =>
              foodRequest.requestStatus === "Approved_Pending_Payment" ||
              foodRequest.requestStatus === "Pending"
          )
          .map((foodRequest) => {
            return (
              <a
                href="#"
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
                style={{
                  backgroundColor:
                    foodRequest.requestStatus === "Approved_Pending_Payment"
                      ? "darkseagreen"
                      : "white",
                }}
              >
                <img
                  src={
                    foodRequest.postOwnerImage ? foodRequest.postOwnerImage : dp
                  }
                  alt="twbs"
                  width="32"
                  height="32"
                  class="rounded-circle flex-shrink-0"
                />
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">{foodRequest.postOwnerUserName}</h6>
                    <p class="mb-0 opacity-75">
                      {" "}
                      You have requested to {
                        foodRequest.postOwnerUserName
                      } for{" "}
                      <Link
                        to={`/post/${
                          foodRequest &&
                          foodRequest.postID &&
                          foodRequest.postID._id
                            ? foodRequest.postID._id
                            : foodRequest.postID
                        }`}
                      >
                        this
                      </Link>{" "}
                      food.
                      <br />
                      {foodRequest.requestStatus ===
                      "Approved_Pending_Payment" ? (
                        getPostDetails(
                          foodRequest &&
                            foodRequest.postID &&
                            foodRequest.postID._id
                            ? foodRequest.postID._id
                            : foodRequest.postID,
                          foodRequest
                        )
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">
                    <ReactTimeAgo date={foodRequest.createdAt} locale="en-US" />
                  </small>
                </div>
              </a>
            );
          })}
      </div>
      <div>
        <>
          {isModalOpen ? (
            <Backdrop show={isModalOpen} onClose={closeModal}>
              <div className="rounded modal-body">
                <div className="p-3">
                  <div>
                    <h2>Food Details</h2>
                    <h4>
                      <b>
                        Your request has been approved by{" "}
                        {postDetails ? postDetails.userDetails.name : ""} and{" "}
                        <br />
                        {postDetails && postDetails.isDonate ? (
                          <>you have nothing to pay for it </>
                        ) : (
                          <>you can pay the {postDetails.amount} € </>
                        )}{" "}
                        to the food owner and get your food now.
                      </b>
                      <br />
                      {}
                    </h4>
                  </div>
                  <div>
                    {postDetails && postDetails.isDonate ? (
                      <></>
                    ) : (
                      <Payment
                        postDetails={postDetails}
                        foodRequestDetails={foodRequestDetails}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Backdrop>
          ) : (
            <></>
          )}
        </>
      </div>
    </section>
  );
};

export default FoodRequest;
