import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { setIsLoading } from "../../features/modalSlice";
import { sendForgotOTPService } from "../../services/authServices";
import DataList from "../DataList/DataList";
import { fullLogo } from "../../assets";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const customFetch = useFetch();

  // TODO: Uncomment this code for OTP verification on the Forgot page if necessary.
  //const [isSendOTP, setIsSendOTP] = useState(false);
  //const [forgotOTP, setForgotOTP] = useState("");

  const sendOTP = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    const data = await customFetch(sendForgotOTPService, { email });
    dispatch(setIsLoading(false));
    //redirect to otp page with userid from data.userId
    navigate(`/changepassword/${data.data.userId}`);
  };

  return (
    <>
      <section className="auth signup">
        <form onSubmit={sendOTP} className="forgotPassword">
          <div className="login-title">
            <img src={fullLogo} alt="logo" />
          </div>
          <div className="email">
            <label htmlFor="forgot-email">Email</label>
            <input
              type="email"
              id="forgot-email"
              placeholder="johndoe@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <DataList email={email} setEmail={setEmail} />
          </div>
          {/* TODO: Uncomment this code for OTP verification on the Forgot page if necessary.

                     {isSendOTP &&
                        <>
                            <label htmlFor="forgot-otp">OTP</label>
                            <input
                                type="password"
                                id="forgot-otp"
                                placeholder="otp"
                                value={forgotOTP}
                                onChange={(e) => setForgotOTP(e.target.value)}
                            />
                        </>
                    } */}
          <button type="submit">Send OTP</button>
          <p>
            Remember the password? <br />
            <Link to="/">Login</Link>
          </p>
          {/* TODO: Uncomment this code for OTP verification on the Forgot page if
          necessary. */}
          {/* {!isSendOTP ? (
            <button type="submit" onClick={() => {}}>
              Send OTP
            </button>
          ) : (
            <button type="submit">Verify OTP</button>
          )} */}
        </form>
      </section>
    </>
  );
};

export default ForgotPassword;
