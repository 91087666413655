import axiosConfig from "./axiosConfig";

export const getTransactionsService = async () => {
  const { data } = await axiosConfig.get("/transactions/byuser");
  return data;
};

export const createTransactionService = async (formData = {}) => {
  const { data } = await axiosConfig.post("/transactions/create", formData);
  return data;
};

export const createPaymentIntentService = async (formData = {}) => {
  const { data } = await axiosConfig.post(
    "/transactions/create-payment-intent",
    formData
  );
  return data;
};

export const getAccountDetailsService = async (formData = {}) =>{
  const {data} = await axiosConfig.post(
    "/transactions/retriveAccountDetails",
    formData
  );
  return data;
}

export const getAccountLinkService = async (formData = {}) =>{
  const {data} = await axiosConfig.post(
    "/transactions/generateAccountLinks",
    formData
  );
  return data;
}
